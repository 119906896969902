import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {
  usersdetail = JSON.parse(localStorage.getItem('userdetails') || 'null');
  
  constructor(private title: Title,private _router:Router){
    this.title.setTitle('Home | Viezu');
    console.log('Updated build 19th jan 2025');
    
  }

  goToShop() {
    if(this)
    if(this.usersdetail && !this.usersdetail.is_evc_customer) {
      this._router.navigate(['/admin/shop/products'])
    } 
    else if(this.usersdetail && this.usersdetail.is_evc_customer) {
      this._router.navigate(['/credit/shop'])
    } else {
      this._router.navigate(['/auth/login'])
    }
  }
}
